import Cookies from 'js-cookie';

$(document).on('turbolinks:load', () => {
  $('#js-sidebar-collapse-trigger').click(() => {
    $('#js-sidebar').addClass('is-collapse');
    Cookies.set('sidebar_collapsed', true, { expires: 365, sameSite: 'lax' });
  });

  $('#js-sidebar-expand-trigger').click(() => {
    $('#js-sidebar').removeClass('is-collapse');
    Cookies.set('sidebar_collapsed', false, { expires: 365, sameSite: 'lax' });
  })
});
