// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
import App from 'app';
import Rails from '@rails/ujs'
import 'bootstrap-icons/bootstrap-icons.svg'
import 'bootstrap-star-rating/css/star-rating.css'
import 'bootstrap-star-rating/themes/krajee-svg/theme.css'
import 'animate.css'

window.$ = $;
window.Rails = Rails
window.App = App;

require('stylesheets/application')
require('select2')

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('bootstrap')
require('clip_submission.js')
require('clips_table.js')
require('clips_search.js')
require('toast.js')
require('clip_signature.js')
require('sidebar.js')
require('bootstrap-star-rating')
require('clip_signature.js')



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
