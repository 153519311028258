$(document).on('turbolinks:load', () => {
  $('#js-clips-search-form').submit((event) => {
    event.preventDefault();

    const query = $('#js-clips-search-input').val();

    window.clipTable.setFilter([
      [{ field: 'title', type:'like', value: query }, { field: 'collab_employee', type:'like', value: query }]
    ]);
  })
});
