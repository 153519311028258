import { ajax } from '@rails/ujs';
import Tabulator from 'tabulator-tables';
import Pikaday from "pikaday";
import SignaturePad from 'signature_pad'

export default {
  renderContent(selector, content) {
    $(selector).html(content)
  },

  renderClipTable() {
    if ($('#js-clips-table').length == 0 ) { return; }
    if (window.clipTable) { window.clipTable.destroy() }

    window.clipTable = new Tabulator('#js-clips-table', {
      height: '100%',
      virtualDom: false,
      layout: 'fitDataFill',
    });

    setTimeout(() => {
      window.clipTable.redraw(true);
    }, 100)
  },

  renderToast() {
    $('.toast').toast({
      delay: 2500,
    });

    $('.toast').toast('show');
  },

  loadClipSignature() {
    const signatureCanvas = $('#js-signature-canvas')[0];

    if (!signatureCanvas) { return; }

    const signaturePad = new SignaturePad(signatureCanvas, {
      backgroundColor: 'rgb(255, 255, 255)'
    });

    setTimeout(() => {
      resizeCanvas();
    }, 0);

    signaturePad.addEventListener("endStroke", () => {
      $('#js-signature-field').val(signaturePad.toDataURL());
    });

    window.addEventListener("resize", resizeCanvas);

    $('#js-signature-clear-button').click(function(event) {
      event.preventDefault();
      signaturePad.clear();
      $('#js-signature-field').val('');
    });

    function resizeCanvas() {
      let ratio =  Math.max(window.devicePixelRatio || 1, 1);

      signatureCanvas.width = signatureCanvas.offsetWidth * ratio;
      signatureCanvas.height = signatureCanvas.offsetHeight * ratio;
      signatureCanvas.getContext("2d").scale(ratio, ratio);

      signaturePad.clear();
    }
  },

  loadClipSubmission() {
    $('#js-video-file-field').change(function() {
      $("#js-video-file-button-text").text('Uploading...');
      $('#js-video-file-button-spinner').removeClass('d-none');

      Rails.fire($(this.form)[0], 'submit');
    });

    $('#js-video-links-select').select2({
      tags: true,
      width: '100%'
    });

    $('#js-tags-select').select2({
      tags: true,
      width: '100%'
    });

    $('#js-genre-select').select2({
      tags: true,
      width: '100%'
    })

    $('#js-platform-select').select2({
      tags: true,
      width: '100%'
    })

    $('#js-country-select').select2({
      width: '100%'
    })

    $('#js-country-select').on('select2:select', (event) => {
      let data = event.params.data;
      this.countryFieldChangeTo(data.id)
    });

    this.countryFieldChangeTo($('#js-country-select').val())

    $('#js-indonesia-province-select').select2({
      width: '100%'
    })

    $('.js-date-filed').each(function() {
      new Pikaday({
        field: $(this)[0],
        maxDate: new Date(),
        yearRange: 100
      })
    })

    new Pikaday({
      field: $("#js-birthday-filed")[0],
      maxDate: new Date(),
      yearRange: 100,
      onSelect: function() {
        var eighteenYearsAgo = new Date();
        eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-18);

        // if user isn't a adult then indicate the guardian name field is required.
        if (this.getDate() > eighteenYearsAgo) {
          $('#js-guardian-name-badge').removeClass('d-none');
        } else {
          $('#js-guardian-name-badge').addClass('d-none');
        }
      }
    })

    $('#js-avatar-upload').change(function() {
      if (this.files && this.files[0]) {
        let reader = new FileReader();

        reader.onload = function(e) {
          $('#js-avatar-preview').attr('src', e.target.result);
        }

        reader.readAsDataURL(this.files[0]);
      }
    });

    $('#js-captcha-refresh-button').click(function(event) {
      event.preventDefault();
      $('#js-captcha-image').trigger('click');
    });

    $('.js-star-rating').rating({ showClear: false, showCaption: false, size: 'xs'});

    $('#js-clip-reject-modal').on('show.bs.modal', function(event) {
      let clipId = $(event.relatedTarget).data('clipId')

      $(this).find('#rejected_reason').val('')
      $(this).find('form').attr('action', `/dashboard/clips/${clipId}/status?status=rejected`)
    });
  },

  countryFieldChangeTo: (countryCode) => {
    if (countryCode === 'ID') {
      $('#js-indonesia-province-form-group').removeClass('d-none')
      $('#js-state-form-group').addClass('d-none')
      $('#js-indonesia-province-select').prop('disabled', false)
      $('#js-state-text-field').prop('disabled', true)
    } else {
      $('#js-indonesia-province-form-group').addClass('d-none')
      $('#js-state-form-group').removeClass('d-none')
      $('#js-indonesia-province-select').prop('disabled', true)
      $('#js-state-text-field').prop('disabled', false)
    }
  }
}
